html,
body,
#root {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    width: 13px;
    padding: 0;
    margin: 0;
}

.page__container {
    background-color: #fff;
    flex-grow: 1;
}

.link {
    color: #000;
}

.no-decoration {
    text-decoration: none;
}

.btn-clear {
    padding: 0;
    border: 0;
    cursor: pointer;
    outline: none;
    background-color: transparent;
}

.divider-black {
    background-color: #000 !important;
}

.alert-close {
    position: relative;
}

.alert-close__btn {
    position: absolute !important;
    right: 2px;
    top: 2px;
}

.hidden {
    visibility: hidden;
}
